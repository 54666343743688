<template>
  <div class="page">
    <Nav></Nav>
    <main class="wrap">
      <div class="main">
        <div class="shoppingcart">
          <div class="head">
            <div class="check">
              <a-checkbox :checked="ckAll" @change="onCheckAll">全选</a-checkbox>
            </div>
            <div class="info">商品信息</div>
            <div class="t1">单价</div>
            <div class="t1">数量</div>
            <div class="t1">小计</div>
            <div class="t1">操作</div>
          </div>
          <div class="goods" v-for="(item, index) in list" :key="index">
            <div class="ck"><a-checkbox :value="index" :checked="item.checked" @change="onChange"></a-checkbox></div>
            <div class="img"><img :src="item.imgSrc" width="100" height="100" /></div>
            <div class="title">
              <div style="height: 25px;">
                {{ item.title }}
              </div>
              <div style="color: #999;">{{ item.specName }}</div>
            </div>
            <div class="pr">¥{{ item.price }}</div>
            <div class="t1"><a-input-number v-model="item.count" :min="1" :max="1000"
                @change="onChangeNumber({ index: index, value: item.count })" /></div>
            <div class="pr">¥{{ item.price * item.count }}</div>
            <div class="t1">
              <a-button type="link" @click="deletepro(item.id,item.specId)">
                删除
              </a-button>
            </div>
          </div>
          <div class="fool">
            <div class="check">
              <a-checkbox :checked="ckAll" @change="onCheckAll">已选({{ checkList.length }})</a-checkbox>
            </div>
            <div class="delete" @click="deleteCheck">
              <a-button type="link">批量删除</a-button>
            </div>
            <div class="info">
              商品合计:<font class="price">¥ {{ totalPrice }}</font>
            </div>
            <div class="btn">
              <a-button type="primary" class="order" @click="buy">
                下单
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <foot></foot>
  </div>
</template>

<script>

import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";

export default {
  name: "Home",
  components: {
    Nav,
    foot,
  },
  data() {
    return {
      ckAll: false,
      list: [],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    checkToral: {
      get() {
        let count = 0;
        this.checkList.forEach(item => {
          count += item.count;
        });
        return count;
      },
    },
    ids() {
      return this.checkList.map(item => {
        return item.id;
      })
    },
    checkList: {
      get() {
        var ck = [];
        this.list.filter(item => {
          if (item.checked == true) {
            ck.push(item);
          }
        });
        return ck;
      },
    },
    totalPrice: {
      get() {
        return this.list.reduce((total, item) => total + (this.checkList.indexOf(item) !== -1 ? item
          .price * item.count : 0), 0);
      },
      set() { }
    }
  },
  methods: {
    //请求数据
    loadData() {
      this.api.getCartProduct().then((res) => {
        var list = res;
        list.forEach(item => {
          item.checked = false;
        });
        this.list = list;
      }).catch((err) => {
        console.log(err);
      });
    },
    //全选
    onCheckAll(e) {
      this.ckAll = e.target.checked;
      this.list.forEach(item => {
        item.checked = this.ckAll;
      });
    },
    //选择商品
    onChange(e) {     
      this.list[e.target.value].checked = e.target.checked;
      if (this.checkList.length == this.list.length) {
        this.ckAll = true;
      } else {
        this.ckAll = false;
      }
    },
    //删除
    deletepro(id,specId) {
      this.api.deleteCartProduct({ ids: id+','+specId }).then(() => {
        this.loadData();
      }).catch((err) => {
        console.log(err);
      });
    },
    //删除选中
    deleteCheck() {
      let ids ='';
      this.checkList.forEach(item => {
        ids+=(item.id+','+item.specId+';');
      });

      this.api.deleteCartProduct({ ids: ids }).then(() => {
        this.loadData();
      }).catch((err) => {
        console.log(err);
      });
    },
    //改变数量
    onChangeNumber(e) {
      this.api.updateCartProduct({ id: this.list[e.index].id, specId: this.list[e.index].specId, count: e.value }).then(() => {
      }).catch((err) => {
        console.log(err);
      });
    },
    //购买
    buy() {
      if (this.checkList.length == 0) {
        this.$message.warning('请选择结算商品！');
        return false;
      }
      let order = {};
      order.product = this.checkList;
      order.remark = "";
      this.storage.setItem("order", order);
      this.$router.push("/buy");
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #fff;
}

.main .shoppingcart {
  padding-bottom: 40px;
  margin-top: 10px;
}

.main .shoppingcart .head {
  margin-bottom: 20px;
  padding: 12px 0;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  display: flex;
}

.main .shoppingcart .head .check {
  width: 120px;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 12px 0;
  background-color: #f5f5f5;
}

.main .shoppingcart .head .info {
  width: 100%;
  margin-bottom: 20px;
  margin-left: 50px;
  padding: 12px 0;
  background-color: #f5f5f5;
}

.main .shoppingcart .head .t1 {
  width: 180px;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 12px 0;
  background-color: #f5f5f5;
}

.main .shoppingcart .goods {
  margin-bottom: 20px;
  background-color: #fffbf0;
  display: flex;
  height: 120px;
  line-height: 120px;
}

.main .shoppingcart .goods .ck {
  width: 60px;
  margin-left: 20px;
}

.main .shoppingcart .goods .img {
  width: 180px;
  margin-left: 20px;
}

.main .shoppingcart .goods .title {
  width: 100%;
  margin-left: 20px;
}

.main .shoppingcart .goods .pr {
  width: 180px;
  margin-left: 20px;
  color: #d4282d;
}

.main .shoppingcart .goods .t1 {
  width: 180px;
  margin-left: 20px;
}

.main .shoppingcart .fool {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  display: flex;
}

.main .shoppingcart .fool .check {
  width: 160px;
  margin-left: 20px;
  padding: 12px 0;
  background-color: #f5f5f5;
  height: 40px;
  line-height: 40px;
}

.main .shoppingcart .fool .delete {
  width: 160px;
  margin-left: 20px;
  padding: 12px 0;
  background-color: #f5f5f5;
  height: 40px;
  line-height: 40px;
}


.main .shoppingcart .fool .info {
  width: 100%;
  margin-left: 20px;
  padding: 12px 0;
  background-color: #f5f5f5;
  height: 40px;
  line-height: 40px;
  padding-right: 20px;
  text-align: right;
}

.main .shoppingcart .fool .price {
  padding-left: 20px;
  font-size: 22px;
  font-weight: 700;
  color: #BE4141;
}

.main .shoppingcart .fool .btn {
  width: 150px;
  margin-left: 20px;
  background-color: #f5f5f5;
}

.main .shoppingcart .fool .order {
  width: 150px;
  height: 60px;
  background-color: #b4a078;
  border: 1px solid #b4a078;
  font-size: 18px;
}
</style>
